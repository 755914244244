import { httpClient } from './httpClient.js';

const
    LISTROLES = 'roles',
    UPDATEUSER = 'users/',
    GETUSER = 'users/',
    CREATEUSER = 'users';

const listRoles = () => httpClient.auth.get(LISTROLES);
const getUser = (id) => httpClient.auth.get(GETUSER + id);
const createUser = (email, name, password, role_id) => httpClient.auth.post(CREATEUSER, { "email": email, "password": password, "name": name, "role_id": role_id });
const updateUser = (id, email, name, role_id) => httpClient.auth.put(UPDATEUSER + id, { "email": email, "name": name, "role_id": role_id });


export {
    listRoles,
    getUser,
    createUser,
    updateUser
};
