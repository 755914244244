<template>
  <CRow>
    <CCol col="12" lg="6">
      <CCard>
        <CCardHeader
          >User id: {{ $route.params.id }}
          <CButton
            @click="goTo(`/users/${$route.params.id}/edit`)"
            class="btn btn-sm btn-warning float-right"
          >
            Edit
          </CButton>
        </CCardHeader>
        <CCardBody :v-show="showuserDetails">
          <CDataTable
            small
            fixed
            :items="visibleData"
            :fields="fields"
          />
        </CCardBody>
        <CCardFooter>
          <CButton color="durianprimary" @click="goBack">{{ $t("COMMON.BACK_BUTTON") }}</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { getUser } from "@/api/user.api.js";

export default {
  name: "User",
  data() {
    return {
      user: {
        id: null,
        name: null,
        roleId: null,
        email: null,
      },
      usersOpened: null,
      showuserDetails: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes("users");
    });
  },
  methods: {
    getUserDetails() {
      getUser(this.$route.params.id)
        .then((response) => {
          let user = response.data.data;
          this.user.id = user.id;
          this.user.name = user.name;
          this.user.email = user.email;
          this.user.roleId = user.roleId;
          this.showuserDetails = true;
        })
        .catch((e) => {
          if (e.response.data.error) this.showToaster(e.response.data.error);
        });
    },
  },
  computed: {
    fields() {
      if (this.showuserDetails) {
        return [
          { key: "key", label: this.user.name, _style: "width:150px" },
          { key: "value", label: "", _style: "width:150px;" },
        ];
      }
      return [];
    },
    visibleData() {
      const userDetails = this.user
        ? Object.entries(this.user)
        : [["id", "Not found"]];
      return userDetails.map(([key, value]) => {
        return { key, value };
      });
    },
  },
  mounted() {
    this.getUserDetails();
  },
};
</script>
